import { throwError } from 'rxjs';
import { User } from '../model/user';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { ErrorDataService } from './error-data.service';
import { factory } from '../shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('UserService');
export class UserService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.fetchUserLastCallError = false;
        this.fetchUserMenuLastCallError = false;
        this.fetchUserOrganizationsLastCallError = false;
    }
    getUserDetails() {
        if (this.user && !this.fetchUserLastCallError) {
            logger.debug('getUserDetails: Cache hit.');
        }
        this.user = (this.user && !this.fetchUserLastCallError) ? this.user : this.fetchUserDetails();
        return this.user;
    }
    fetchUserDetails() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlUserDetails).pipe(map(response => {
            const dataDetails = response['data']['details'];
            const user = new User(dataDetails, dataDetails.user_id, dataDetails.username, dataDetails.user_type, dataDetails.user_class);
            logger.debug(`${user.username} user fetched from REST`);
            this.fetchUserLastCallError = false;
            return user;
        }), catchError((err) => {
            this.fetchUserLastCallError = true;
            const message = `In UserService.fetchUser().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    getUserMenu(user) {
        if (this.menu && !this.fetchUserMenuLastCallError) {
            logger.debug('getUserMenu: Cache hit.');
        }
        this.menu = (this.menu && !this.fetchUserMenuLastCallError) ? this.menu : this.fetchUserMenu(user);
        return this.menu;
    }
    fetchUserMenu(user) {
        let urlService = AppConfig.urlUserMenu;
        urlService = urlService.replace('{user_id}', user.userId);
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            const data = response['data'];
            logger.debug(`${JSON.stringify(data)} user menu fetched from REST`);
            this.fetchUserMenuLastCallError = false;
            return data;
        }), catchError((err) => {
            this.fetchUserMenuLastCallError = true;
            const message = `In UserService.fetchUserMenu().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    getUserOrganizations(user) {
        if (this.organizations && !this.fetchUserOrganizationsLastCallError) {
            logger.debug('getUserOrganizations: Cache hit.');
        }
        this.organizations = (this.organizations && !this.fetchUserOrganizationsLastCallError) ? this.organizations :
            this.fetchUserOrganizations(user);
        return this.organizations;
    }
    fetchUserOrganizations(user) {
        let urlService = AppConfig.urlUserOrganizations;
        urlService = urlService.replace('{user_id}', user.userId);
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            const data = response['data'];
            logger.debug(`${JSON.stringify(data)} user organizations fetched from REST`);
            this.fetchUserOrganizationsLastCallError = false;
            return data;
        }), catchError((err) => {
            this.fetchUserOrganizationsLastCallError = true;
            const message = `In UserService.fetchUserOrganizations().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function UserService_Factory(t) { return new (t || UserService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserService, factory: UserService.ɵfac, providedIn: 'root' }); }
}
