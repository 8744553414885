import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Locale } from '../model/locale';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('LocalesService');
export class LocalesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.locales = {};
        this.localesLastCallError = {};
    }
    findLocalesMock() {
        return new Observable((observer) => {
            // observable execution
            observer.next([new Locale('1', { en_US: 'English (US)', it_IT: 'Inglese (Stati Uniti)', nb_NO: '[儲굻ßEnglish (US)Дß굻]' }, 'en_US', 'AMERICAN', 'AMERICA', '1', '1')]);
            observer.complete();
        });
    }
    findLocales(platformLocalisedInd = 1) {
        if (this.locales[platformLocalisedInd] && !this.localesLastCallError[platformLocalisedInd]) {
            logger.debug('findLocales: Cache hit.');
        }
        this.locales[platformLocalisedInd] = (this.locales[platformLocalisedInd] && !this.localesLastCallError[platformLocalisedInd]) ?
            this.locales[platformLocalisedInd] :
            this.fetchLocales(platformLocalisedInd);
        return this.locales[platformLocalisedInd];
    }
    fetchLocales(platformLocalisedInd) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('X-Api-Key', environment.ngpServiceCatalogReferenceApiKey);
        return this.httpClient.get(environment.ngpServiceCatalogReferenceApiBaseUrl + AppConfig.urlLocales, { headers: headers }).pipe(map(response => {
            const locales = [];
            for (const item of response['data']) {
                // Filter locales
                if (!isNaN(item.platform_localised_ind) && parseInt(item.platform_localised_ind, 10) === platformLocalisedInd) {
                    const locale = new Locale(item.id, item.display_labels, item.locale_name, item.nls_language, item.nls_territory, item.platform_localised_ind, item.unit_system_id);
                    locales.push(locale);
                }
                else {
                    // logger.debug(`Ignoring locale: ${item.locale_name}`);
                }
            }
            locales.sort((l1, l2) => {
                return l1.displayLabels.en_US > l2.displayLabels.en_US ? 1 : -1;
            });
            logger.debug(`findLocales: ${locales.length} locales with platform_localised_ind = ${platformLocalisedInd} fetched from REST`);
            this.localesLastCallError[platformLocalisedInd] = false;
            return locales;
        }), catchError((err) => {
            const message = `In LocalesService.findLocales().`;
            this.localesLastCallError[platformLocalisedInd] = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function LocalesService_Factory(t) { return new (t || LocalesService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LocalesService, factory: LocalesService.ɵfac, providedIn: 'root' }); }
}
