import { LoggerFactoryOptions, LogGroupRule, LogLevel } from 'typescript-logging';
import { CustomLFService } from './logging/logging-wrapper';
// Create options instance and specify 3 LogGroupRules:
// * One for components
// * The second one for services
// * The third one for error handling
// * The fourth one for everything else
const componentsLogLevel = LogLevel.Debug;
const servicesLogLevel = LogLevel.Debug;
const errorHandlingLogLevel = LogLevel.Debug;
const defaultLogLevel = LogLevel.Info;
const options = new LoggerFactoryOptions()
    .addLogGroupRule(new LogGroupRule(new RegExp('.+Component$'), componentsLogLevel))
    .addLogGroupRule(new LogGroupRule(new RegExp('.+Service$'), servicesLogLevel))
    .addLogGroupRule(new LogGroupRule(new RegExp('.+(Handler|Interceptor)$'), errorHandlingLogLevel))
    .addLogGroupRule(new LogGroupRule(new RegExp('.+'), defaultLogLevel));
// Create a named loggerfactory and pass in the options and export the factory.
export const factory = CustomLFService.createNamedLoggerFactory('LoggerFactory', options);
