import { Program } from './program';
export class Product {
    constructor(id, program, displayLabel, status, timezone, timezone_short, timezone_long) {
        this.id = id;
        this.program = program;
        this.displayLabel = displayLabel;
        this.status = status;
        this.timezone = timezone;
        this.timezone_short = timezone_short;
        this.timezone_long = timezone_long;
    }
    static newInstance(id, programId, displayLabel, status, timezone, timezone_short, timezone_long) {
        return new Product(id, new Program(programId), displayLabel, status, timezone, timezone_short, timezone_long);
    }
    clone() {
        return new Product(this.id, this.program, this.displayLabel, this.status, this.timezone, this.timezone_short, this.timezone_long);
    }
}
