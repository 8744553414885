export class User {
    constructor(userDetails, userId = '', username = (new Date()).toISOString(), userType, userClass) {
        this.userDetails = userDetails;
        this.userId = userId;
        this.username = username;
        this.userType = userType;
        this.userClass = userClass;
        Object.assign(this, userDetails);
        let names = this.profile.names;
        if (!names.first_name) {
            const locales = Object.keys(names);
            names = names[locales[0]];
        }
        this.profile.names = names;
    }
}
