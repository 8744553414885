import { EventEmitter } from '@angular/core';
import { factory } from '../../../shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
import * as i2 from "@angular/material/table";
const _c0 = ["sharedTemplatesModal"];
function SharedTemplatesModalComponent_th_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 17);
    i0.ɵɵtext(1, "Template");
    i0.ɵɵelementEnd();
} }
function SharedTemplatesModalComponent_td_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const element_r7 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", element_r7.displayLabel, " ");
} }
function SharedTemplatesModalComponent_th_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 17);
    i0.ɵɵtext(1, "Product Line");
    i0.ɵɵelementEnd();
} }
function SharedTemplatesModalComponent_td_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const element_r8 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", element_r8.productLine, " ");
} }
function SharedTemplatesModalComponent_tr_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tr", 19);
} }
function SharedTemplatesModalComponent_tr_17_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tr", 20);
} }
const _c1 = function () { return { backdrop: "static" }; };
const logger = factory.getLogger('SharedTemplatesModalComponent');
export class SharedTemplatesModalComponent {
    constructor() {
        this.close = new EventEmitter();
        this.displayedColumns = ['template', 'productLine'];
    }
    ngOnChanges(changes) {
        if (changes.templateComponent?.currentValue || (changes.sharedTemplates?.currentValue && changes.sharedTemplates?.currentValue.length > 0)) {
            changes.sharedTemplates?.currentValue.sort((a, b) => {
                if (a.displayLabel > b.displayLabel) {
                    return 1;
                }
                if (a.displayLabel < b.displayLabel) {
                    return -1;
                }
                return 0;
            });
            this.sharedTemplatesModal.show();
        }
    }
    closeModal() {
        this.sharedTemplatesModal.hide();
        this.close.emit(true);
    }
    static { this.ɵfac = function SharedTemplatesModalComponent_Factory(t) { return new (t || SharedTemplatesModalComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SharedTemplatesModalComponent, selectors: [["app-shared-templates-modal"]], viewQuery: function SharedTemplatesModalComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, 7);
        } if (rf & 2) {
            let _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sharedTemplatesModal = _t.first);
        } }, inputs: { templateComponent: "templateComponent", sharedTemplates: "sharedTemplates", templatesTotalCount: "templatesTotalCount" }, outputs: { close: "close" }, features: [i0.ɵɵNgOnChangesFeature], decls: 21, vars: 7, consts: [["bsModal", "", "tabindex", "-1", "role", "dialog", "aria-labelledby", "sharedTemplatesModalLabel", 1, "modal", "fade", 3, "config"], ["sharedTemplatesModal", "bs-modal"], ["role", "document", 1, "modal-dialog", "modal-dialog-centered", "modal-dialog-scrollable", "modal-xl"], [1, "modal-content"], [1, "modal-header"], ["id", "previewModalLabel", 1, "modal-title"], ["id", "sharedTemplatesModalHeaderClose", "type", "button", "data-dismiss", "modal", "aria-label", "Close", 1, "btn-close", "btn-close-white", 3, "click"], [1, "modal-body"], ["mat-table", "", 1, "mat-elevation-z8", 3, "dataSource"], ["matColumnDef", "template"], ["mat-header-cell", "", 4, "matHeaderCellDef"], ["mat-cell", "", 4, "matCellDef"], ["matColumnDef", "productLine"], ["mat-header-row", "", 4, "matHeaderRowDef"], ["mat-row", "", 4, "matRowDef", "matRowDefColumns"], [1, "modal-footer"], ["id", "sharedTemplatesModalFooterClose", "type", "button", "data-dismiss", "modal", 1, "col-md-3", "col-md-offset-6", "text-right", "btn", "btn-default", "btn-sm", 3, "click"], ["mat-header-cell", ""], ["mat-cell", ""], ["mat-header-row", ""], ["mat-row", ""]], template: function SharedTemplatesModalComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0, 1)(2, "div", 2)(3, "div", 3)(4, "div", 4)(5, "h4", 5);
            i0.ɵɵtext(6);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(7, "button", 6);
            i0.ɵɵlistener("click", function SharedTemplatesModalComponent_Template_button_click_7_listener() { return ctx.closeModal(); });
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(8, "div", 7)(9, "table", 8);
            i0.ɵɵelementContainerStart(10, 9);
            i0.ɵɵtemplate(11, SharedTemplatesModalComponent_th_11_Template, 2, 0, "th", 10);
            i0.ɵɵtemplate(12, SharedTemplatesModalComponent_td_12_Template, 2, 1, "td", 11);
            i0.ɵɵelementContainerEnd();
            i0.ɵɵelementContainerStart(13, 12);
            i0.ɵɵtemplate(14, SharedTemplatesModalComponent_th_14_Template, 2, 0, "th", 10);
            i0.ɵɵtemplate(15, SharedTemplatesModalComponent_td_15_Template, 2, 1, "td", 11);
            i0.ɵɵelementContainerEnd();
            i0.ɵɵtemplate(16, SharedTemplatesModalComponent_tr_16_Template, 1, 0, "tr", 13);
            i0.ɵɵtemplate(17, SharedTemplatesModalComponent_tr_17_Template, 1, 0, "tr", 14);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(18, "div", 15)(19, "button", 16);
            i0.ɵɵlistener("click", function SharedTemplatesModalComponent_Template_button_click_19_listener() { return ctx.closeModal(); });
            i0.ɵɵtext(20, " Close ");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("config", i0.ɵɵpureFunction0(6, _c1));
            i0.ɵɵadvance(6);
            i0.ɵɵtextInterpolate2("Component \"", ctx.templateComponent == null ? null : ctx.templateComponent.displayLabel, "\" shared across ", (ctx.sharedTemplates == null ? null : ctx.sharedTemplates.length) == ctx.templatesTotalCount ? "all" : ctx.sharedTemplates == null ? null : ctx.sharedTemplates.length, " templates");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("dataSource", ctx.sharedTemplates);
            i0.ɵɵadvance(7);
            i0.ɵɵproperty("matHeaderRowDef", ctx.displayedColumns);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("matRowDefColumns", ctx.displayedColumns);
        } }, dependencies: [i1.ModalDirective, i2.MatTable, i2.MatHeaderCellDef, i2.MatHeaderRowDef, i2.MatColumnDef, i2.MatCellDef, i2.MatRowDef, i2.MatHeaderCell, i2.MatCell, i2.MatHeaderRow, i2.MatRow] }); }
}
