import { TemplateDeliveryPath } from '../model/template-delivery-path';
import { AppConfig } from '../shared/app-config';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('TemplateDeliveryPathsService');
export class TemplateDeliveryPathsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.templateDeliveryPathsLastCallError = false;
    }
    findTemplateDeliveryPaths() {
        if (this.templateDeliveryPaths && !this.templateDeliveryPathsLastCallError) {
            logger.debug('findTemplateDeliveryPaths: Cache hit.');
        }
        this.templateDeliveryPaths = (this.templateDeliveryPaths && !this.templateDeliveryPathsLastCallError) ?
            this.templateDeliveryPaths : this.fetchTemplateDeliveryPaths();
        return this.templateDeliveryPaths;
    }
    fetchTemplateDeliveryPaths() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplateDeliveryPaths).pipe(map(response => {
            const deliveryPaths = [];
            for (const item of response['data']) {
                deliveryPaths.push(new TemplateDeliveryPath(item.delivery_path));
            }
            logger.debug(`findTemplateDeliveryPaths: ${deliveryPaths.length} delivery paths fetched from REST`);
            this.templateDeliveryPathsLastCallError = false;
            return deliveryPaths;
        }), catchError((err) => {
            const message = `In TemplateDeliveryPathsService.findTemplateDeliveryPaths().`;
            this.templateDeliveryPathsLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function TemplateDeliveryPathsService_Factory(t) { return new (t || TemplateDeliveryPathsService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplateDeliveryPathsService, factory: TemplateDeliveryPathsService.ɵfac, providedIn: 'root' }); }
}
