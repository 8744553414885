import { BulkEditChangeTypeEnum } from './bulk-edit-change-type.enum';
import { AppUtils } from '../shared/app-utils';
import { RelevantBulkEditChangesFilterPipe } from '../pipes/bulk-edit/relevant-bulk-edit-changes-filter.pipe';
import { factory } from '../shared/app-logging';
const logger = factory.getLogger('BulkEditSession');
export class ExcelFileReadMessage {
    constructor(title, description) {
        this.title = title;
        this.description = description;
    }
}
export class ExcelFileReadEvent {
    constructor(result, warnings, errors) {
        this.result = result;
        this.warnings = warnings;
        this.errors = errors;
    }
}
export class BulkEditSession {
    constructor(file, binStringFileContent, generationDate, locale, product, changes, parsingDate, error) {
        this.file = file;
        this.binStringFileContent = binStringFileContent;
        this.generationDate = generationDate;
        this.locale = locale;
        this.product = product;
        this.changes = changes;
        this.parsingDate = parsingDate;
        this.error = error;
        this.result = new Array();
        this.parsingDate = parsingDate ? parsingDate : new Date();
        if (!changes) {
            this.changes = new Map();
            for (const changeType of AppUtils.getEnumIndexes(BulkEditChangeTypeEnum)) {
                this.changes.set(BulkEditChangeTypeEnum[changeType], []);
            }
        }
    }
    getChangeTypes() {
        const result = [];
        if (!this.changes) {
            return result;
        }
        const keyIterator = this.changes.keys();
        let key = keyIterator.next();
        while (!key.done) {
            result.push(key.value);
            key = keyIterator.next();
        }
        return result;
    }
    getChangesToPerform() {
        const changeTypesToPerform = RelevantBulkEditChangesFilterPipe.filter(this.getChangeTypes(), this, false);
        let changesToPerform = [];
        for (const type of changeTypesToPerform) {
            changesToPerform = changesToPerform.concat(this.changes.get(type));
        }
        return changesToPerform;
    }
    getChangesPerformed() {
        const changesPerformed = [];
        for (const res of this.result) {
            changesPerformed.push(res.bulkEditRecord);
        }
        return changesPerformed;
    }
    hasResultErrors() {
        return this.result.some(res => res.bulkEditRecord.error !== undefined);
    }
    getResultErrors() {
        return this.result.filter(res => res.bulkEditRecord.error !== undefined);
    }
    isDefaultCombination() {
        return this.locale.localeName === 'en_US' && (!this.product || !this.product.id || this.product.displayLabel === 'DEFAULT');
    }
}
