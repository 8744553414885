export class Operator {
    constructor(id, displayLabel, description, status) {
        this.id = id;
        this.displayLabel = displayLabel;
        this.description = description;
        this.status = status;
    }
    clone() {
        return new Operator(this.id, this.displayLabel, this.description, this.status);
    }
}
