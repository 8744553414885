import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class SafeHTMLPipe {
    constructor(sanitizer) {
        this.sanitizer = sanitizer;
    }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    static { this.ɵfac = function SafeHTMLPipe_Factory(t) { return new (t || SafeHTMLPipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16)); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "safeHTML", type: SafeHTMLPipe, pure: true }); }
}
