import { TemplateProductLine } from '../model/template-product-line';
import { AppConfig } from '../shared/app-config';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('BulkEditExcelService');
export class TemplateProductLinesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.templateProductLinesLastCallError = false;
    }
    findTemplateProductLines() {
        if (this.templateProductLines && !this.templateProductLinesLastCallError) {
            logger.debug('findTemplateProductLines: Cache hit.');
        }
        this.templateProductLines = (this.templateProductLines && !this.templateProductLinesLastCallError) ?
            this.templateProductLines : this.fetchTemplateProductLines();
        return this.templateProductLines;
    }
    fetchTemplateProductLines() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplateProductLines).pipe(map(response => {
            const productLines = [];
            for (const item of response['data']) {
                productLines.push(new TemplateProductLine(item.product_line));
            }
            logger.debug(`findTemplateProductLines: ${productLines.length} product lines fetched from REST`);
            this.templateProductLinesLastCallError = false;
            return productLines;
        }), catchError((err) => {
            const message = `In TemplateProductLinesService.findTemplateProductLines().`;
            this.templateProductLinesLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function TemplateProductLinesService_Factory(t) { return new (t || TemplateProductLinesService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplateProductLinesService, factory: TemplateProductLinesService.ɵfac, providedIn: 'root' }); }
}
