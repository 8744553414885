import { RouterModule } from '@angular/router';
import { TemplateDefinitionComponent } from './components/templates/template-definition.component';
import { TemplateBulkEditComponent } from './components/template-bulk-edit/template-bulk-edit.component';
import { ErrorReportComponent } from './components/error-report/error-report.component';
import { environment } from '../environments/environment';
import { RedirectGuard } from './guards/redirect.guard';
import { TemplatePriorityComponent } from './components/template-priority/template-priority.component';
import { TEMPLATE_BULK_EDIT, TEMPLATE_BULK_EDIT_ROUTE, TEMPLATE_DEFINITION, TEMPLATE_DEFINITION_ROUTE, TEMPLATE_PRIORITY, TEMPLATE_PRIORITY_ROUTE } from './components/common/common';
const routes = [
    { path: TEMPLATE_DEFINITION_ROUTE, data: { title: TEMPLATE_DEFINITION }, component: TemplateDefinitionComponent },
    { path: TEMPLATE_BULK_EDIT_ROUTE, data: { title: TEMPLATE_BULK_EDIT }, component: TemplateBulkEditComponent },
    { path: 'error', data: { title: 'Error Report' }, component: ErrorReportComponent },
    { path: 'error/:code', data: { title: 'Error Report' }, component: ErrorReportComponent },
    { path: 'login', canActivate: [RedirectGuard],
        data: { externalUrl: environment.openAmUiLoginUrl, title: 'Login' }, component: ErrorReportComponent },
    { path: 'logout', canActivate: [RedirectGuard],
        data: { externalUrl: environment.openAmUiLogoutUrl, logout: true }, component: ErrorReportComponent },
    { path: TEMPLATE_PRIORITY_ROUTE, data: { title: TEMPLATE_PRIORITY }, component: TemplatePriorityComponent },
    { path: `${TEMPLATE_PRIORITY_ROUTE}/:edit`, data: { title: TEMPLATE_PRIORITY }, component: TemplatePriorityComponent },
    // Set default page to "template-definition"
    { path: '', redirectTo: '/template-definition', pathMatch: 'full' },
    { path: '**', redirectTo: '/template-definition', pathMatch: 'full' }
];
export const routingModule = RouterModule.forRoot(routes);
