export function messageBreakTag(index, toastr) {
    const msg = `Please add a "s" or "ms" on after the character ${index} for the pause, or the pause might not work`;
    toastr.warning(msg, undefined, {
        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        progressBar: false,
    }).toastId;
    return { checkBreakTag: false, text: "" };
}
export function checkBreakTag(breakTag, saveBreakTag, toastr) {
    // check if break tag has a number
    // if is a number it will check if it has a 's' or 'ms' to make sure the break tag will reproduce.
    // if it has a string (medium, strong...) will return true
    return breakTag.map((data) => {
        const hasNumber = /\d/;
        if (saveBreakTag && hasNumber.test(data[0])) {
            if (data[0].search("s") <= -1) {
                return messageBreakTag(data.index, toastr);
            }
            else {
                return { checkBreakTag: true, text: data[0] };
            }
        }
        return { checkBreakTag: true, text: data[0] };
    });
}
export function checkVoiceMail(saveBreakTag, toastr, templateDefinitionSel, customizingContentEditorText) {
    if (templateDefinitionSel.deliveryPath.code === "VOICE") {
        const getBreakTagData = checkBreakTag([...customizingContentEditorText.matchAll(/(?=strength)(.*?)(?=>)|(?=time)(.*?)(?=>)/g)], saveBreakTag, toastr);
        // return every data with the break tag
        // Ej: { checkBreakTag: true/false, text: '<break time='5.0' /> // <break time='medium' }
        return getBreakTagData;
    }
    return [{ checkBreakTag: false, text: customizingContentEditorText }];
}
export function removeRepeated(array) {
    return [
        ...new Set(array),
    ];
}
export function replaceIndexArray(index, position, data, array) {
    return array.splice(index, position, data);
}
export const TEMPLATE_PRIORITY = 'Template Priority';
export const TEMPLATE_DEFINITION = 'Template Definition';
export const TEMPLATE_BULK_EDIT = 'Template Bulk Edit';
export const TEMPLATE_PRIORITY_ROUTE = 'template-priority';
export const TEMPLATE_DEFINITION_ROUTE = 'template-definition';
export const TEMPLATE_BULK_EDIT_ROUTE = 'template-bulk-edit';
