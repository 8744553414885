import { ErrorHandler } from '@angular/core';
import { factory } from '../app-logging';
import { HttpErrorResponse } from '@angular/common/http';
import { RedirectService } from '../../services/redirect.service';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
const logger = factory.getLogger('GlobalErrorHandler');
export class GlobalErrorHandler extends ErrorHandler {
    constructor(injector) {
        super();
        this.injector = injector;
    }
    handleError(error) {
        const redirector = this.injector.get(RedirectService);
        const toastr = this.injector.get(ToastrService);
        logger.trace('Handling error');
        logger.error(error.message, error);
        if (error instanceof HttpErrorResponse) {
            const code = isNaN(error.status) ? NaN : error.status;
            switch (code) {
                case NaN:
                    toastr.error('An unknown error rose while processing a request.', 'Unexpected response');
                    break;
                case 0: // Possibly this is due to a CORS Error
                    toastr.error('The request could not be fulfilled. It might be caused due to a ' +
                        'Cross-Origin Resource Sharing error, please contact the service administrator.', 'CORS Error');
                    redirector.goToErrorPage(error);
                    break;
                default:
                    toastr.error(error.message, error.name);
                    break;
            }
        }
        else {
            toastr.error(error.message, error.name);
        }
        super.handleError(error);
    }
    static { this.ɵfac = function GlobalErrorHandler_Factory(t) { return new (t || GlobalErrorHandler)(i0.ɵɵinject(i0.Injector)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GlobalErrorHandler, factory: GlobalErrorHandler.ɵfac }); }
}
