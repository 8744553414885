export class AppConfig {
    /** URL base App */
    static { this.urlBase = '/v1'; }
    /** URL to test if the current user is logged into IAM and have permissions to access the API */
    static { this.urlPing = AppConfig.urlBase + '/ping'; }
    /** URL to get all templates data */
    static { this.urlTemplates = AppConfig.urlBase + '/templates'; }
    /** URL to get all template's delivery paths data */
    static { this.urlTemplateDeliveryPaths = AppConfig.urlBase + '/delivery-paths'; }
    /** URL to get all template's product lines data */
    static { this.urlTemplateProductLines = AppConfig.urlBase + '/product-lines'; }
    /** URL to get all template's component types data */
    static { this.urlTemplateComponentTypes = AppConfig.urlBase + '/component-type'; }
    /** URL to get the data of all contents of component by template name, delivery path and location */
    static { this.urlTempCompContsByTempNameDelPathLoc = AppConfig.urlBase +
        '/templates/{template_name}/components/{delivery_path}/contents/{locale}'; }
    /** URL to get the data of all contents of component by template name, delivery path, location and product*/
    static { this.urlTempCompContsByTempNameDelPathLocProg = AppConfig.urlBase +
        '/templates/{template_name}/components/{delivery_path}/contents/{locale}/products/{product_id}'; }
    /** URL to get the data of a component by id */
    static { this.urlTempCompById = AppConfig.urlBase + '/components/{template_component_id}'; }
    /** URL to get all Operators */
    static { this.urlOperators = AppConfig.urlBase + '/operators'; }
    /** URL to get all pairs of Operator-Programs */
    static { this.urlPrograms = AppConfig.urlBase + '/programs'; }
    /** URL to get all pairs of Program-Products */
    static { this.urlProducts = AppConfig.urlBase + '/products'; }
    /** URL to export Template-Priority */
    static { this.urlTemplatePriorityExport = AppConfig.urlBase + '/priority/export'; }
    /** URL to get all template's component variables */
    static { this.urlTemplateComponentVariables = AppConfig.urlBase + '/template-component-variables'; }
    /** URL to GET or DELETE a template component content by ID */
    static { this.urlTempCompContsById = AppConfig.urlBase + '/contents/{template_component_content_id}'; }
    /** URL to POST a template component content data */
    static { this.urlTempCompConts = AppConfig.urlBase + '/contents'; }
    /** URL to GET a template component content data by component and locale */
    static { this.urlTempCompContsByCompLoc = AppConfig.urlBase + '/components/{template_component_id}/contents/{locale}'; }
    /** URL to get the data of all contents of component by template name, delivery path, location and product*/
    static { this.urlCompsContsByLocProg = AppConfig.urlBase +
        '/components-contents/locale/{locale}/product/{product_id}'; }
    /** URL to GET a template component content data by component and locale */
    static { this.urlAuditBulkEditTCC = AppConfig.urlBase + '/audit-bulk-edit'; }
    /** URL to POST Text To Speech */
    static { this.urlTTS = AppConfig.urlBase + '/tts'; }
    /** URL to POST list voices and locales */
    static { this.urlPollyVoices = AppConfig.urlBase + '/polly/describeVoices'; }
    /** URL to GET list of TTS locale mappings xcore -> aws polly */
    static { this.urlTtsLocaleMappers = AppConfig.urlBase + '/mapper/aws_locale'; }
    /** URL to GET list of TTS locale mappings xcore -> aws polly */
    static { this.urlTtsXcoreLocales = AppConfig.urlBase + '/mapper/aws_locale/polly-to-xcore'; }
    /** URL to GET list of TTS locale mappings xcore -> aws polly */
    static { this.urlTtsPollyLocale = AppConfig.urlBase + '/mapper/aws_locale/xcore-to-polly'; }
    /** URL to GET list of TTS locale mappings xcore -> aws polly */
    static { this.urlTtsApplicationDefaultsByXcoreLocaleAndApplication = AppConfig.urlBase + '/tts_application_defaults/locale/{xcore_locale}/application_name/{tts_application_name}'; }
    /** URL to get User Details */
    static { this.urlUserDetails = AppConfig.urlBase + '/users/details'; }
    /** URL to get User Menu */
    static { this.urlUserMenu = AppConfig.urlBase + '/users/{user_id}/menu'; }
    /** URL to get User Organizations */
    static { this.urlUserOrganizations = AppConfig.urlBase + '/users/{user_id}/organizations'; }
    /** URL base Reference Data */
    static { this.urlBaseRefData = '/v3'; }
    /** URL to get all Locales */
    static { this.urlLocales = AppConfig.urlBaseRefData + '/locales'; }
    /** URL base Info-Push Service */
    static { this.urlBaseInfoPush = '/v1'; }
    /** URL to request for notification preview */
    static { this.urlPreviewInfoPush = AppConfig.urlBaseInfoPush + '/preview'; }
    /** URL to Priority */
    static { this.urlPriorities = AppConfig.urlBase + '/priorities'; }
    static { this.urlPrioritiesBulk = AppConfig.urlBase + '/priorities/bulk'; }
    static { this.urlPriority = AppConfig.urlBase + '/priority'; }
    /** URL to Events */
    static { this.urlEvents = AppConfig.urlBase + '/event_types'; }
    /** URL to template names */
    static { this.urlTemplateNames = AppConfig.urlBase + '/template_names'; }
    /** Timeout in ms for each request in HTTP Interceptor **/
    static { this.requestTimeout = 15 * 1000; }
    /** Retry count for HttpErrors in HTTP Interceptor **/
    static { this.retryCountHttpRequestError = 3; }
    /** Retry delay in msec that will be scaled in every attempt **/
    static { this.retryScalingDelayMs = 300; }
    /** Session token variable name in the cookie **/
    static { this.sessionTokenVariableName = 'enoc_session'; }
    static { this.sessionUsernameVariableName = 'user_name'; }
    /** TTS Config */
    static { this.ttsSourceApp = 'XCore-ContentLibrary'; }
    static { this.brandList = [
        { label: 'Consumers Energy', value: 'CONSUMERSENERGY' },
        { label: 'Enel X', value: 'ENELX' }
    ]; }
}
