import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function ConfirmModalComponent_div_7_dsy_input_with_message_3_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "dsy-input-with-message", 14)(1, "div", 15)(2, "dsy-icon", 16);
    i0.ɵɵlistener("click", function ConfirmModalComponent_div_7_dsy_input_with_message_3_Template_dsy_icon_click_2_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r3.inputType()); });
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵpropertyInterpolate("type", ctx_r1.type);
    i0.ɵɵpropertyInterpolate1("id", "", ctx_r1.idTitle, "-password");
    i0.ɵɵproperty("handleChange", ctx_r1.handleChange);
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("name", ctx_r1.inputName);
} }
function ConfirmModalComponent_div_7_p_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 17);
    i0.ɵɵtext(1, "Password is not correct");
    i0.ɵɵelementEnd();
} }
function ConfirmModalComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9)(1, "div", 10)(2, "div", 11);
    i0.ɵɵtemplate(3, ConfirmModalComponent_div_7_dsy_input_with_message_3_Template, 3, 4, "dsy-input-with-message", 12);
    i0.ɵɵtemplate(4, ConfirmModalComponent_div_7_p_4_Template, 2, 0, "p", 13);
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.passwordResetToDefault);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.passwordValue && !ctx_r0.correctPass);
} }
export class ConfirmModalComponent {
    constructor() {
        this.title = 'Template Priority';
        this.outcome = new EventEmitter();
        this.customHeader = true;
        this.passwordValue = '';
        this.idTitle = this.title.split(" ").join('-').toLowerCase();
        this.type = 'password';
        this.inputName = 'visibility';
        this.correctPass = false;
        this.passwordResetToDefault = false;
        this.handleOpenClose = (event) => {
            this.passwordValue = '';
            this.outcome.emit({ status: false, value: 'cancel' });
        };
        this.applyModal = () => {
            this.passwordValue = '';
            this.outcome.emit({ status: true, value: 'apply' });
        };
        this.handleChange = (value) => {
            this.passwordValue = value;
            if (this.passwordValue === 'ennel1') {
                this.correctPass = true;
            }
            else {
                this.correctPass = false;
            }
        };
        this.inputType = () => {
            this.inputName === "visibility_off"
                ? ((this.type = "password"), (this.inputName = "visibility"))
                : ((this.type = "text"), (this.inputName = "visibility_off"));
        };
    }
    ngOnChanges(changes) {
        if (changes?.openModal?.currentValue) {
            this.passwordResetToDefault = false;
            setTimeout(() => {
                this.passwordResetToDefault = true;
            }, 100);
        }
    }
    static { this.ɵfac = function ConfirmModalComponent_Factory(t) { return new (t || ConfirmModalComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ConfirmModalComponent, selectors: [["app-confirm-modal"]], inputs: { openModal: "openModal", title: "title", message: "message", requirePassword: "requirePassword" }, outputs: { outcome: "outcome" }, features: [i0.ɵɵNgOnChangesFeature], decls: 13, vars: 5, consts: [["title-label", "'modal title'", "subtitle-label", "Modal subtitle", "custom-header", "", "custom-footer", "", "z-index", "1", "width", "650px", "closes-on-apply", "", "background-overlay-hidden", "", 3, "isOpen"], ["slot", "header"], [1, "fs-1"], [1, "row"], [1, "mr-2", "col-11"], ["class", "row require-password-container", 4, "ngIf"], [1, "modal-footer-container"], ["size", "large", "variant", "primary", "label", "dsy-button", "button-style", "", "type", "misc", 3, "disabled", "click"], ["size", "large", "variant", "tertiary", "label", "dsy-button", "button-style", "", "type", "misc", 3, "click"], [1, "row", "require-password-container"], [1, "col-12"], [1, "col-10"], ["input-status", "default", "placeholder", "Password", "label", "Enter Password", 3, "type", "id", "handleChange", 4, "ngIf"], ["class", "error", 4, "ngIf"], ["input-status", "default", "placeholder", "Password", "label", "Enter Password", 3, "type", "id", "handleChange"], ["slot", "right", 1, "icon-container"], ["library", "material", "size", "16px ", "density", "default", "elevation", "auto", "material-weight", "400", "material-grade", "0", 1, "iconVisibility", 3, "name", "click"], [1, "error"]], template: function ConfirmModalComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "dsy-modal", 0)(1, "div", 1)(2, "p", 2);
            i0.ɵɵtext(3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "div", 3)(5, "span", 4);
            i0.ɵɵtext(6);
            i0.ɵɵelementEnd()();
            i0.ɵɵtemplate(7, ConfirmModalComponent_div_7_Template, 5, 2, "div", 5);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(8, "div", 6)(9, "dsy-button", 7);
            i0.ɵɵlistener("click", function ConfirmModalComponent_Template_dsy_button_click_9_listener() { return ctx.applyModal(); });
            i0.ɵɵtext(10, " Confirm ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "dsy-button", 8);
            i0.ɵɵlistener("click", function ConfirmModalComponent_Template_dsy_button_click_11_listener($event) { return ctx.handleOpenClose($event.target); });
            i0.ɵɵtext(12, " Cancel ");
            i0.ɵɵelementEnd()()();
        } if (rf & 2) {
            i0.ɵɵproperty("isOpen", ctx.openModal);
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate(ctx.title);
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate(ctx.message);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.requirePassword);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.requirePassword && !ctx.correctPass);
        } }, dependencies: [i1.NgIf], styles: [".iconVisibility[_ngcontent-%COMP%] {\n  cursor: pointer;\n}\n\n.error[_ngcontent-%COMP%] {\n  color: #a94442;\n  font-size: 15px;\n  font-weight: 900;\n  margin-top: 5px;\n}\n\n.require-password-container[_ngcontent-%COMP%] {\n  margin-top: 10px;\n}"] }); }
}
