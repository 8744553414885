import { catchError, of, from, timer, concatMap, toArray, delayWhen, forkJoin } from 'rxjs';
import { factory } from '@/app/shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "@/app/services/export.service";
import * as i2 from "@/app/services/template-priority.service";
import * as i3 from "./opp/opp.component";
import * as i4 from "./templates/templates.component";
import * as i5 from "./modal/confirm-modal/confirm-modal.component";
const logger = factory.getLogger('TemplatePriorityComponent');
export class TemplatePriorityComponent {
    constructor(exportService, templatePriorityService) {
        this.exportService = exportService;
        this.templatePriorityService = templatePriorityService;
        this.subscriptions$ = [];
        //create element icon
        this.iconInfo = document.createElement('dsy-icon');
        this.lengthByScreen = 4;
        this.editEnabled = false;
        this.openExportModal = false;
        this.opps = [];
        this.dropdownSelectedEntries = [];
        this.defaultTemplatePriorities = [];
        this.templatePriorities = [];
        this.editedTemplatePriorities = [];
        this.openSaveModal = false;
        this.exportModalMessage = 'This action will allow to export into a CSV file the template priorities configured for the products selected. Proceed?';
    }
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
        this.getScreenHeight = window.innerHeight;
        if (window.innerWidth < 1600 && window.innerWidth > 500) {
            this.lengthByScreen = 3;
        }
        else if (window.innerWidth < 500) {
            this.lengthByScreen = 0;
        }
        else {
            this.lengthByScreen = 4;
        }
    }
    ngOnInit() {
        this.onWindowResize();
        // subscribe to template priority state changes
        this.subscriptions$.push(this.templatePriorityService.getAction().subscribe((value) => {
            if (value === 'EDIT') {
                this.editEnabled = true;
            }
            if (value === 'CANCEL') {
                this.editEnabled = false;
                this.templatePriorityService.setIsSaveEnabled(false);
            }
            if (value === 'SAVE') {
                if (this.dropdownSelectedEntries.length == 0 || (this.dropdownSelectedEntries.length == 1 && this.dropdownSelectedEntries[0].product.id == null)) {
                    this.saveModalMessage = 'Any changes done to the Default product will apply to all templates that have not been modified. Proceed?';
                }
                else if (this.dropdownSelectedEntries.length == 0 || (this.dropdownSelectedEntries.length == 1 && this.dropdownSelectedEntries[0].product.id == 'ALL')) {
                    this.saveModalMessage = 'Changes will be applied to all Operator, Programs and Products. Proceed?';
                }
                else {
                    this.saveModalMessage = 'Do you want to confirm the changes?';
                }
                this.openSaveModal = true;
            }
        }));
        // subscribe to template priority OPPs
        this.subscriptions$.push(this.templatePriorityService.getOpps().subscribe((value) => {
            this.opps = value;
        }));
        // subscribe to template priority selected OPPs changes
        this.subscriptions$.push(this.templatePriorityService.getDropdownSelectedEntries().subscribe((value) => {
            this.dropdownSelectedEntries = value;
        }));
        // subscribe to template default priority list
        this.subscriptions$.push(this.templatePriorityService.getDefaultTemplatePriorities().subscribe((value) => {
            this.defaultTemplatePriorities = value;
        }));
        // subscribe to template priority list
        this.subscriptions$.push(this.templatePriorityService.getTemplatePriorities().subscribe((value) => {
            this.templatePriorities = value;
        }));
        // subscribe to template priority form changes
        this.subscriptions$.push(this.templatePriorityService.getEditedTemplatePriorities().subscribe((value) => {
            this.editedTemplatePriorities = value;
        }));
        // subscribe to template priority errors
        this.subscriptions$.push(this.templatePriorityService.getError().subscribe((value) => {
            this.error = value;
        }));
        // Subscribe to Export Modal
        this.subscriptions$.push(this.exportService.getOpenExportModal().subscribe(value => {
            this.openExportModal = value;
        }));
    }
    ngOnDestroy() {
        this.subscriptions$.forEach(sub => sub.unsubscribe());
    }
    _saveDefaultTemplatePriorities() {
        this.templatePriorityService.setIsLoadingActive(true);
        // Find differences
        const templatePrioritiesToUpdate = [];
        this.editedTemplatePriorities.forEach((editedTemplatePriority) => {
            const defaultTemplatePriority = this.templatePriorities.find((tp) => tp.template_name == editedTemplatePriority.template_name);
            if (JSON.stringify(defaultTemplatePriority) !== JSON.stringify(editedTemplatePriority)) {
                templatePrioritiesToUpdate.push(editedTemplatePriority);
            }
        });
        const templatePriorityRequests = [];
        templatePrioritiesToUpdate.forEach((templatePriorityToUpdate) => {
            if (!templatePriorityToUpdate.customizations || templatePriorityToUpdate.customizations.length == 0) {
                // Update with no custom priorities by event type
                templatePriorityToUpdate.event_types.forEach((eventType) => {
                    templatePriorityRequests.push({
                        template_name: templatePriorityToUpdate.template_name,
                        event_type: eventType,
                        priority: templatePriorityToUpdate.priority,
                        product_id: null
                    });
                });
            }
            else {
                // Update with custom priorities by event type
                templatePriorityToUpdate.customizations.forEach((customPriority) => {
                    templatePriorityRequests.push({
                        template_name: templatePriorityToUpdate.template_name,
                        event_type: customPriority.event_type,
                        priority: customPriority.priority,
                        product_id: null
                    });
                });
            }
        });
        this._saveTemplatePriorities(templatePriorityRequests);
    }
    _saveCustomTemplatePriorities(allOpps = false) {
        this.templatePriorityService.setIsLoadingActive(true);
        const templatePrioritiesToUpdate = [];
        const templatePrioritiesToUpdateForSingleOpp = [];
        // Find differences 
        this.editedTemplatePriorities.forEach((editedTemplatePriority) => {
            const defaultTemplatePriority = this.templatePriorities.find((tp) => tp.template_name == editedTemplatePriority.template_name);
            if (JSON.stringify(editedTemplatePriority) !== JSON.stringify(defaultTemplatePriority)) {
                templatePrioritiesToUpdate.push(editedTemplatePriority);
            }
        });
        const dropdownEntries = allOpps ? this.opps.map((product) => {
            return {
                labelContent: '',
                isSelected: true,
                hasBorderAbove: false,
                hasBorderBelow: false,
                product: product
            };
        }) : this.dropdownSelectedEntries;
        const templatePriorityRequests = [];
        templatePrioritiesToUpdate.forEach((templatePriorityToUpdate) => {
            if (!templatePriorityToUpdate.customizations || templatePriorityToUpdate.customizations.length == 0) {
                // Update with no custom priorities by event type for each selected product
                dropdownEntries.forEach((dropdownEntry) => {
                    templatePriorityToUpdate.event_types.forEach((eventType) => {
                        templatePriorityRequests.push({
                            template_name: templatePriorityToUpdate.template_name,
                            event_type: eventType,
                            priority: templatePriorityToUpdate.priority,
                            product_id: dropdownEntry.product.id
                        });
                    });
                });
            }
            else {
                // Update with custom priorities by event type for each selected product
                templatePriorityToUpdate.customizations.forEach((customPriority) => {
                    dropdownEntries.forEach((dropdownEntry) => {
                        templatePriorityRequests.push({
                            template_name: templatePriorityToUpdate.template_name,
                            event_type: customPriority.event_type,
                            priority: customPriority.priority,
                            product_id: dropdownEntry.product.id
                        });
                    });
                });
            }
        });
        this._saveTemplatePriorities(templatePriorityRequests);
    }
    _saveTemplatePriorities(requests) {
        // Divide requests into chunks of chunkSize
        const chunkSize = 500;
        const chunks = [];
        for (let i = 0; i < requests.length; i += chunkSize) {
            chunks.push(requests.slice(i, i + chunkSize));
        }
        // for each chunk, save the template priorities
        const forkJoinUpdates = [];
        for (let i = 0; i < chunks.length; i++) {
            const chunk = chunks[i];
            forkJoinUpdates.push(this.templatePriorityService.saveTemplatePriorities(chunk)
                .pipe(catchError((err) => {
                this.error = err?.error?.message;
                return of(err);
            })));
        }
        const delayedForkJoinUpdates = from(forkJoinUpdates).pipe(concatMap((update, index) => timer(50).pipe(concatMap(() => update))), // 100ms delay between each request
        toArray());
        forkJoin(delayedForkJoinUpdates)
            .subscribe((saveTemplatePrioritiesResponses) => {
            this.templatePriorityService.setIsLoadingActive(false);
            this.templatePriorityService.setAction('SAVE_OK');
            this.templatePriorityService.setIsSaveEnabled(false);
            if (saveTemplatePrioritiesResponses.find((response) => response !== 'OK')) {
                this.error = 'An error occurred! Some priorities could not be saved.';
                this.templatePriorityService.setError(this.error);
            }
        });
    }
    handleSaveModalOutcome(event) {
        if (event.status && this.dropdownSelectedEntries) {
            if (this.dropdownSelectedEntries.length == 0 || (this.dropdownSelectedEntries.length == 1 && this.dropdownSelectedEntries[0].product.id == null)) {
                // DEFAULT
                this._saveDefaultTemplatePriorities();
            }
            else if (this.dropdownSelectedEntries.length == 0 || (this.dropdownSelectedEntries.length == 1 && this.dropdownSelectedEntries[0].product.id == 'ALL')) {
                // ALL
                this._saveCustomTemplatePriorities(true);
            }
            else {
                // FILTERED
                this._saveCustomTemplatePriorities(false);
            }
        }
        this.openSaveModal = false;
    }
    // Export
    handleExportModalOutcome(proceed) {
        logger.info('Handled export-modal outcome: ' + proceed);
        this.exportService.setOpenExportModal(false);
        if (proceed.status) {
            this.exportService.setIsLoadingActive(true);
            const exportRequest = {
                export_type: null,
                opp_list: ''
            };
            if (this.dropdownSelectedEntries.length === 1 && this.dropdownSelectedEntries[0]?.product?.id == 'ALL') {
                // Export ALL
                exportRequest.export_type = 'ALL';
                exportRequest.opp_list = this.opps.map((product) => {
                    return {
                        product_id: product.id,
                        product_display_label: product.displayLabel,
                        program_id: product.program?.id,
                        program_display_label: product.program?.displayLabel,
                        operator_id: product.program?.operator?.id,
                        operator_display_label: product.program?.operator?.displayLabel
                    };
                });
            }
            else if (this.dropdownSelectedEntries.length === 1 && this.dropdownSelectedEntries[0]?.product?.id == null) {
                // Export DEFAULT
                exportRequest.export_type = 'DEFAULT';
            }
            else {
                // Export Filtered
                exportRequest.export_type = 'FILTERED';
                exportRequest.opp_list = this.dropdownSelectedEntries.map((entry) => {
                    return {
                        product_id: entry.product.id,
                        product_display_label: entry.product.displayLabel,
                        program_id: entry.product.program?.id,
                        program_display_label: entry.product.program?.displayLabel,
                        operator_id: entry.product.program?.operator?.id,
                        operator_display_label: entry.product.program?.operator?.displayLabel
                    };
                });
            }
            const exportRequestChunks = this._createExportRequestChunks(exportRequest);
            this._joinCsv(exportRequest.export_type, exportRequestChunks);
        }
    }
    _createExportRequestChunks(request) {
        const oppList = request.opp_list;
        const chunkSize = 150;
        const chunks = [];
        for (let i = 0; i < oppList.length; i += chunkSize) {
            const chunk = oppList.slice(i, i + chunkSize);
            chunks.push({ export_type: request.export_type, opp_list: btoa(encodeURIComponent(JSON.stringify(chunk))) });
        }
        if (chunks.length === 0) {
            chunks.push(request);
        }
        return chunks;
    }
    _joinCsv(exportType, requests) {
        from(requests).pipe(
        // Use concatMap to introduce a delay between each request
        concatMap(request => this.exportService.exportTemplatePriority(request).pipe(
        // Delay the next request
        delayWhen(() => timer(500)), catchError((err) => {
            this.error = err?.error?.message;
            return of(err);
        }))), 
        // Collect all responses into an array
        toArray())
            .subscribe(results => {
            // 'results' contains the responses of all requests, processed sequentially with a delay
            let sheet1Header = '';
            let sheet1Body = '';
            let sheet2Header = '';
            let sheet2Body = '';
            let sheet2HeaderKeys = [];
            const sheet2Rows = [];
            results.filter(result => result.code === 200).forEach((response) => {
                if (response.sheets) {
                    // Build Sheet 1
                    if (response.sheets.length > 0) {
                        if (!sheet1Header) {
                            sheet1Header = response.sheets[0].header;
                        }
                        sheet1Body += !sheet1Body ? response.sheets[0].body : '\n' + response.sheets[0].body;
                    }
                    // Build Sheet 2
                    if (response.sheets.length > 1) {
                        const sheet2HeaderKeysTemp = response.sheets[1].header.split(',');
                        sheet2HeaderKeys = sheet2HeaderKeys.concat(sheet2HeaderKeysTemp.filter(key => !sheet2HeaderKeys.includes(key)));
                        const sheet2BodyRows = response.sheets[1].body.split('\n');
                        sheet2BodyRows.forEach((row) => {
                            const rowValues = row.split(',');
                            const rowObject = {};
                            sheet2HeaderKeysTemp.forEach((key, index) => {
                                rowObject[key] = rowValues[index];
                            });
                            sheet2Rows.push(rowObject);
                        });
                    }
                }
            });
            // Download Sheet 1
            const isoDate = new Date().toISOString();
            if (sheet1Header && sheet1Body) {
                this._downloadCsv(sheet1Header + '\n' + sheet1Body, `template-priority-${exportType}-${isoDate}.csv`);
            }
            // Download Sheet 2
            if (sheet2HeaderKeys.length > 0 && sheet2Rows.length > 0) {
                sheet2Header = sheet2HeaderKeys.join(',');
                sheet2Rows.forEach((row) => {
                    if (sheet2Body) {
                        sheet2Body += '\n';
                    }
                    sheet2HeaderKeys.forEach((key, index) => {
                        const value = (row[key] || '');
                        sheet2Body += index === 0 ? value : ',' + value;
                    });
                });
                this._downloadCsv(sheet2Header + '\n' + sheet2Body, `template-priority-by-event-type-${exportType}-${isoDate}.csv`);
            }
            this.exportService.setIsLoadingActive(false);
        });
    }
    _downloadCsv(csvData, csvName) {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', csvName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    static { this.ɵfac = function TemplatePriorityComponent_Factory(t) { return new (t || TemplatePriorityComponent)(i0.ɵɵdirectiveInject(i1.ExportService), i0.ɵɵdirectiveInject(i2.TemplatesPriorityService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TemplatePriorityComponent, selectors: [["app-template-priority"]], hostBindings: function TemplatePriorityComponent_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("resize", function TemplatePriorityComponent_resize_HostBindingHandler($event) { return ctx.onWindowResize($event); }, false, i0.ɵɵresolveWindow);
        } }, decls: 4, vars: 8, consts: [[3, "lengthByScreen"], [3, "requirePassword", "message", "openModal", "outcome"]], template: function TemplatePriorityComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "app-template-priority-opp", 0)(1, "app-template-priority-templates", 0);
            i0.ɵɵelementStart(2, "app-confirm-modal", 1);
            i0.ɵɵlistener("outcome", function TemplatePriorityComponent_Template_app_confirm_modal_outcome_2_listener($event) { return ctx.handleSaveModalOutcome($event); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "app-confirm-modal", 1);
            i0.ɵɵlistener("outcome", function TemplatePriorityComponent_Template_app_confirm_modal_outcome_3_listener($event) { return ctx.handleExportModalOutcome($event); });
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵproperty("lengthByScreen", ctx.lengthByScreen);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("lengthByScreen", ctx.lengthByScreen);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("requirePassword", true)("message", ctx.saveModalMessage)("openModal", ctx.openSaveModal);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("requirePassword", false)("message", ctx.exportModalMessage)("openModal", ctx.openExportModal);
        } }, dependencies: [i3.TemplatePriorityOppComponent, i4.TemplatePriorityTemplatesComponent, i5.ConfirmModalComponent] }); }
}
