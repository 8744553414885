import { throwError } from 'rxjs';
import { AppConfig } from '../shared/app-config';
import { environment } from '../../environments/environment';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { ErrorDataService } from './error-data.service';
import { TtsLocaleMap } from '../model/ttsLocaleMap';
import { factory } from '../shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('MapperService');
export class MapperService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.ttsLocaleMappingListLastCallError = false;
    }
    getTtsLocaleMappingList() {
        if (this.ttsLocaleMappingList && !this.ttsLocaleMappingListLastCallError) {
            logger.debug('getTtsLocaleMappingList: Cache hit.');
        }
        this.ttsLocaleMappingList = (this.ttsLocaleMappingList && !this.ttsLocaleMappingListLastCallError) ?
            this.ttsLocaleMappingList : this.fetchTtsLocaleMappingList();
        return this.ttsLocaleMappingList;
    }
    fetchTtsLocaleMappingList() {
        const urlService = AppConfig.urlTtsLocaleMappers;
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService)
            .pipe(map((response) => {
            const mappings = new Array();
            if (response['data']) {
                for (const item of response['data']) {
                    mappings.push(new TtsLocaleMap(item.tts_locale_mapper_id, item.xcore_locale, item.tts_polly_locale));
                }
            }
            logger.debug(`getTtsLocaleMappingList: ${mappings.length} mappings fetched from REST`);
            this.ttsLocaleMappingListLastCallError = false;
            return mappings;
        }), catchError((err) => {
            const message = `In MapperService.getLocaleMappingList().`;
            this.ttsLocaleMappingListLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function MapperService_Factory(t) { return new (t || MapperService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MapperService, factory: MapperService.ɵfac, providedIn: 'root' }); }
}
