export class TemplateComponent {
    constructor(id, name, displayLabel, productLine, componentType, deliveryPath, contents, templates) {
        this.id = id;
        this.name = name;
        this.displayLabel = displayLabel;
        this.productLine = productLine;
        this.componentType = componentType;
        this.deliveryPath = deliveryPath;
        this.contents = contents;
        this.templates = templates;
        this.contents = [];
        this.templates = [];
    }
}
