import { environment } from "src/environments/environment";
import { LFService, LogLevel } from "typescript-logging";
const LogLevelNames = {
    [LogLevel.Info]: 'INFO',
    [LogLevel.Debug]: 'DEBUG',
    [LogLevel.Error]: 'ERROR',
    [LogLevel.Fatal]: 'FATAL',
    [LogLevel.Trace]: 'TRACE',
    [LogLevel.Warn]: 'WARN'
};
class CustomLogger {
    constructor(name, group) {
        this.name = name;
        this.group = group;
    }
    trace(msg, error) {
        if (this.isTraceEnabled()) {
            console.trace(this.format({ msg, error }));
        }
    }
    debug(msg, error) {
        if (this.isDebugEnabled()) {
            console.debug(this.format({ msg, error }));
        }
    }
    info(msg, error) {
        if (this.isInfoEnabled()) {
            console.info(this.format({ msg, error }));
        }
    }
    warn(msg, error) {
        if (this.isWarnEnabled()) {
            // console.warn(msg, error);
            console.log(this.format({ msg, error }));
        }
    }
    error(msg, error) {
        if (this.isErrorEnabled()) {
            console.error(this.format({ msg, error }));
        }
    }
    fatal(msg, error) {
        if (this.isFatalEnabled()) {
            console.error(this.format({ msg, error }));
        }
    }
    isTraceEnabled() {
        return LogLevel.Trace >= this.getLogLevel() && !environment.production;
    }
    isDebugEnabled() {
        return LogLevel.Debug >= this.getLogLevel() && !environment.production;
    }
    isInfoEnabled() {
        return LogLevel.Info >= this.getLogLevel();
    }
    isWarnEnabled() {
        return LogLevel.Warn >= this.getLogLevel();
    }
    isErrorEnabled() {
        return LogLevel.Error >= this.getLogLevel();
    }
    isFatalEnabled() {
        return LogLevel.Fatal >= this.getLogLevel();
    }
    getLogLevel() {
        return this.group.level;
    }
    format({ msg, error }) {
        const messageHandled = this.handleMessage(msg);
        const errHandled = error instanceof Error ? error : typeof error === 'function' ? error() : null;
        const finalMessage = {
            loggerName: this.name,
            level: this.getLogLevel(),
            logGroupRule: this.group,
            message: messageHandled,
            isMessageLogData: !!messageHandled.data,
            error: errHandled,
            errorAsStack: errHandled instanceof Error ? errHandled.stack : null,
            date: new Date(),
            messageAsString: typeof messageHandled === 'string' ? messageHandled : `${messageHandled.msg} ${messageHandled.ds(messageHandled.data)}`,
            logData: typeof messageHandled !== 'string' ? messageHandled : null
        };
        const formatter = this.group.formatterLogMessage || this.basicFormatter.bind(this);
        return formatter(finalMessage);
    }
    handleMessage(message) {
        switch (typeof message) {
            case "string":
                return message;
            case "object":
                const msg = { data: message.data, msg: message.msg, ds: () => this.dataToString(message.data) };
                return msg;
            case "function":
                return message();
        }
    }
    dataToString(data) {
        if (!data) {
            return '';
        }
        return JSON.stringify(data);
    }
    basicFormatter(message) {
        const levelStr = LogLevelNames[this.getLogLevel()];
        return `[${levelStr}] [${this.name}] ${message.messageAsString}`;
    }
}
class CustomLoggerFactory {
    // private loggerGroups: Array<LogGroupRule> = []
    constructor(name, options) {
        this.name = name;
        this.options = options;
        this.loggers = new Map();
    }
    configure(options) {
        this.options = options;
    }
    getLogger(named) {
        if (!this.loggers.has(named)) {
            const group = this.options.logGroupRules.find((v) => v.regExp.test(named));
            const logger = new CustomLogger(named, group);
            this.loggers.set(named, logger);
            return logger;
        }
        return this.loggers.get(named);
    }
    isEnabled() {
        return this.options.enabled;
    }
    getName() {
        return this.name;
    }
}
export class CustomLFService extends LFService {
    constructor() {
        super();
    }
    static createNamedLoggerFactory(name, options) {
        // const factory = super.createNamedLoggerFactory(name, options);
        const factory = new CustomLoggerFactory(name, options);
        return factory;
    }
}
