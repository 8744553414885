import { environment } from '../../environments/environment';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ErrorDataService } from './error-data.service';
import { factory } from '../shared/app-logging';
import { AppConfig } from '../shared/app-config';
import { PollyVoice } from '../model/polly-voice';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('PollyService');
export class PollyService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.availableVoicesLastCallError = false;
    }
    getAvailableVoices() {
        if (this.availableVoices && !this.availableVoicesLastCallError) {
            logger.debug('getAvailableVoices: Cache hit.');
        }
        this.availableVoices = (this.availableVoices && !this.availableVoicesLastCallError) ? this.availableVoices : this.fetchAvailableVoices();
        return this.availableVoices;
    }
    fetchAvailableVoices() {
        const urlService = AppConfig.urlPollyVoices;
        return this.httpClient.post(environment.contentLibraryApiBaseUrl + urlService, {})
            .pipe(map((response) => {
            const voices = new Array();
            if (response['data']) {
                logger.debug(`getAvailableVoices: ${response['data'].Voices.length} Polly Voices fetched from REST`);
                if (response['data'].NextToken) {
                    // recursive stuff not needed yet
                    logger.debug(`getAvailableVoices(): Next Token= ${response['data'].NextToken}`);
                }
                for (const item of response['data'].Voices) {
                    voices.push(new PollyVoice(item.Id, item.Gender, item.LanguageCode, item.LanguageName, item.Name));
                }
            }
            this.availableVoicesLastCallError = false;
            return voices;
        }), catchError((err) => {
            const message = `In PollyService.getAvailableVoices().`;
            this.availableVoicesLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function PollyService_Factory(t) { return new (t || PollyService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PollyService, factory: PollyService.ɵfac, providedIn: 'root' }); }
}
