export class Locale {
    constructor(id, displayLabels, localeName, nlsLanguage, nlsTerritory, platformLocalisedInd, unitSystemId) {
        this.id = id;
        this.displayLabels = displayLabels;
        this.localeName = localeName;
        this.nlsLanguage = nlsLanguage;
        this.nlsTerritory = nlsTerritory;
        this.platformLocalisedInd = platformLocalisedInd;
        this.unitSystemId = unitSystemId;
    }
}
