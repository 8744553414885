import * as HttpStatus from 'http-status-codes';
import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../services/error-data.service";
export class ErrorReportComponent {
    constructor(activatedRoute, router, errorDataService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.errorDataService = errorDataService;
    }
    ngOnInit() {
        this.errorDataService.currentError.subscribe(err => this.error = err);
        this.code = this.activatedRoute.snapshot.paramMap.get('code') ? parseInt(this.activatedRoute.snapshot.paramMap.get('code'), 10) : null;
        // subscribe to route changes
        this.router.events.subscribe(() => this.updateOnRouteChange());
    }
    updateOnRouteChange() {
        this.code = parseInt(this.activatedRoute.snapshot.paramMap.get('code'), 10);
    }
    getText() {
        if (this.code === null) {
            return this.error ? this.error.message : '';
        }
        if (this.code === 0) {
            return 'The request could not be fulfilled, possibly due to a ' +
                'CORS (Cross-Origin Resource Sharing) error, please contact the service administrator';
        }
        return isNaN(this.code) ? 'Unknown Error, please contact the service administrator' : HttpStatus.getStatusText(this.code);
    }
    getCode() {
        if (this.code === null) {
            return this.error ? (this.error instanceof HttpErrorResponse ? this.error.status : this.error.name) : '';
        }
        if (this.code === 0 || isNaN(this.code)) {
            return 'Unknown Error';
        }
        return 'Http Error status code: ' + this.code;
    }
    static { this.ɵfac = function ErrorReportComponent_Factory(t) { return new (t || ErrorReportComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.ErrorDataService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ErrorReportComponent, selectors: [["app-error-report"]], decls: 12, vars: 2, consts: [[1, "row"], [1, "col-md-2"], [1, "col-md-8", "p-5"], [1, "card", "card-danger"], [1, "card-header"], [1, "card-title"], [1, "card-body"]], template: function ErrorReportComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "section")(1, "div", 0);
            i0.ɵɵelement(2, "div", 1);
            i0.ɵɵelementStart(3, "div", 2)(4, "div", 3)(5, "div", 4)(6, "h3", 5);
            i0.ɵɵtext(7);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(8, "div", 6)(9, "h4");
            i0.ɵɵtext(10);
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelement(11, "div", 1);
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵadvance(7);
            i0.ɵɵtextInterpolate(ctx.getCode());
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate(ctx.getText());
        } }, styles: ["//[_ngcontent-%COMP%]   extracted[_ngcontent-%COMP%]   by[_ngcontent-%COMP%]   mini-css-extract-plugin\nexport[_ngcontent-%COMP%] {};"] }); }
}
