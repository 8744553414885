import * as i0 from "@angular/core";
export class TemplatePriorityLoaderComponent {
    static { this.ɵfac = function TemplatePriorityLoaderComponent_Factory(t) { return new (t || TemplatePriorityLoaderComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TemplatePriorityLoaderComponent, selectors: [["app-template-priority-loader"]], decls: 6, vars: 0, consts: [[1, "col-md-12"], [1, "round-border", "bg-enelx-light-purple", "padding-md", "loader-message"], [1, "fa", "fa-spinner", "fa-spin", "fa-lg"]], template: function TemplatePriorityLoaderComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "p", 1);
            i0.ɵɵelement(2, "i", 2);
            i0.ɵɵtext(3, "\u00A0 ");
            i0.ɵɵelementStart(4, "strong");
            i0.ɵɵtext(5, "Loading contents, please, wait...");
            i0.ɵɵelementEnd()()();
        } }, styles: [".loader-message[_ngcontent-%COMP%] {\n  color: #461E7D;\n}"] }); }
}
