import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as uuid from 'uuid';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ErrorDataService } from './error-data.service';
import { AppConfig } from '../shared/app-config';
import { factory } from '../shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
import * as i3 from "./user.service";
const logger = factory.getLogger('InfoPushService');
export class InfoPushService {
    constructor(httpClient, authService, userService) {
        this.httpClient = httpClient;
        this.authService = authService;
        this.userService = userService;
    }
    static createPostBody(template_name, product_id, locale, context_data, user_id, delivery_methods, trace_id = uuid.v4(), brand = 'ENELX') {
        const created_dtm = Math.floor((new Date()).getTime() / 1000);
        const body = {
            body: {
                template_name: template_name,
                product_id: product_id,
                locale: locale,
                context_data: context_data,
                // brand,
                recipients: [{
                        user_id: user_id,
                        delivery_methods: delivery_methods
                    }]
            },
            trace_id: trace_id,
            header: {
                id: 'preview-' + trace_id,
                data_md5: 'dataMd5',
                event_type: 'dr_preview',
                sender_service: 'content_library',
                sender_host: environment.contentLibraryUiRootUrl,
                create_dttm: created_dtm,
                expires_dttm: created_dtm + 300,
                destination_service: ['notificationService']
            }
        };
        return body;
    }
    requestPreview(template_name, product_id, locale, context_data, delivery_methods) {
        const urlService = AppConfig.urlPreviewInfoPush;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        const enoc_session = this.authService.getToken();
        const trace_id = uuid.v4();
        logger.debug(`requestPreview(${template_name},${product_id},${locale},${context_data},${delivery_methods}). token= ${enoc_session}, trace_id= ${trace_id}`);
        return this.userService.getUserDetails().pipe(switchMap((user) => {
            logger.debug(`requestPreview(${template_name},${product_id},${locale},${context_data},${delivery_methods}). token= ${enoc_session}, user_id=${user.userId} trace_id= ${trace_id}`);
            const body = InfoPushService.createPostBody(template_name, product_id, locale, context_data, user.userId, delivery_methods, trace_id);
            return this.httpClient.post(environment.infoPushApiBaseUrl + urlService, body, { headers: headers })
                .pipe(map((response) => {
                logger.debug(`Preview request registered under trace_id= ${trace_id} for token= ${enoc_session}`);
                return response['data'];
            }), catchError((err) => {
                const message = `In InfoPushService.requestPreview(${template_name},${product_id},${locale},${context_data},${delivery_methods}).` +
                    ` token= ${enoc_session}, trace_id= ${trace_id}, user_id=${user.userId}`;
                return throwError(ErrorDataService.overLoadError(err, message));
            }));
        }));
    }
    static { this.ɵfac = function InfoPushService_Factory(t) { return new (t || InfoPushService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.UserService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: InfoPushService, factory: InfoPushService.ɵfac, providedIn: 'root' }); }
}
