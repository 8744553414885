import * as Plyr from 'plyr';
export class DefaultPlyrDriver {
    create(params) {
        return new Plyr(params.videoElement, params.options);
    }
    updateSource(params) {
        params.plyr.source = params.source;
    }
    destroy(params) {
        params.plyr.destroy();
    }
}
