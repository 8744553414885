import * as __NgCli_bootstrap_1 from "@angular/platform-browser";
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js';
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_1.platformBrowser().bootstrapModule(AppModule)
    .catch(err => console.log(err));
