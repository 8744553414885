export class TemplateComponentVariable {
    constructor(id, displayLabel, format, sampleContent, sampleContentHtml, productLine, source) {
        this.id = id;
        this.displayLabel = displayLabel;
        this.format = format;
        this.sampleContent = sampleContent;
        this.sampleContentHtml = sampleContentHtml;
        this.productLine = productLine;
        this.source = source;
    }
}
