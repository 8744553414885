import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../modal/confirm-modal/confirm-modal.component";
function SidebarComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4)(1, "span", 5);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "dsy-content-switcher", 6);
    i0.ɵɵlistener("click", function SidebarComponent_div_4_Template_dsy_content_switcher_click_3_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r4); const eventType_r1 = restoredCtx.$implicit; const ctx_r3 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r3.setSwitcherValue($event, eventType_r1)); });
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const eventType_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(eventType_r1.display_label);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("contentList", eventType_r1.switcher)("functionSetterData", ctx_r0.switcherComponent)("setIndexContentChosed", ctx_r0.setIndexContentChosed)("indexContentChosed", eventType_r1.priorityIndex);
} }
export class SidebarComponent {
    constructor() {
        this.eventTypes = [];
        this.dropdownSelectedEntries = [];
        this.close = new EventEmitter();
        this.templateEventTypes = [];
        this.templateEventTypeSwitchers = [];
        this.templateEditedEventTypeSwitchers = [];
        this.defaultHeaderContent = true;
        this.switcherValuesMap = {
            HIGH: 2,
            MEDIUM: 1,
            LOW: 0
        };
        this.openConfirmCustomizationsModal = false;
        //Switcher Button Clicked
        this.switcherComponent = (event) => {
            this.switcherValue = event.title;
        };
        this.setIndexContentChosed = (valueIndex) => {
            this.switcherIndex = valueIndex;
        };
        //Close SideBar
        this.handleOpenCloseInteraction = (value) => {
            this.handleCancelButtonInteraction(value);
        };
        this.handleCancelButtonInteraction = (value) => {
            this.close.emit(value);
        };
        this.handleApplyButtonInteraction = () => {
            this.openConfirmCustomizationsModal = true;
        };
    }
    ngOnChanges(changes) {
        if (changes?.templatePriority?.currentValue) {
            //// Intercepted change in templatePriority
            // Map Template Event Types
            this.templateEventTypes = changes.templatePriority.currentValue.event_types
                .map((value) => ({
                name: value,
                display_label: this._buildEventTypeDisplayLabel(value)
            }));
            //// Add possible missing event types
            // for each customPriority in customization.priorities
            changes.templatePriority.currentValue.customizations?.forEach((customPriority) => {
                // check if the eventType is already present
                if (!this.templateEventTypes.find((eventType) => eventType.name === customPriority.event_type)) {
                    // if not, add it
                    this.templateEventTypes.push({
                        name: customPriority.event_type,
                        display_label: this._buildEventTypeDisplayLabel(customPriority.event_type)
                    });
                }
            });
            // Sort Event Types
            this.templateEventTypes = this.templateEventTypes.sort((a, b) => a.name.localeCompare(b.name));
            // Build Template Event Type Switchers for each event type
            this.templateEventTypeSwitchers = this.templateEventTypes.map((eventType) => {
                // Default priority assigned
                let priorityIndex = this.switcherValuesMap[this.templatePriority.priority];
                let priority = this.templatePriority.priority;
                // check if it's configured a custom priority
                const customPriority = this.templatePriority?.customizations?.find(p => p.event_type === eventType.name);
                if (customPriority) {
                    // if yes, assign the custom priority
                    priorityIndex = this.switcherValuesMap[customPriority.priority];
                    priority = customPriority.priority;
                }
                // return the switcher object
                return {
                    ...eventType,
                    switcher: [
                        { title: "Low", data: { title: "LOW" } },
                        { title: "Medium", data: { title: "MEDIUM" } },
                        { title: "High", data: { title: "HIGH" } }
                    ],
                    priorityIndex: priorityIndex,
                    priority: priority
                };
            });
            this.templateEditedEventTypeSwitchers = JSON.parse(JSON.stringify(this.templateEventTypeSwitchers));
        }
    }
    ngOnInit() {
    }
    setSwitcherValue(event, eventTypeSwitcher) {
        this.templateEditedEventTypeSwitchers.forEach((ets) => {
            if (ets.name === eventTypeSwitcher.name) {
                ets.priority = this.switcherValue;
                ets.priorityIndex = this.switcherIndex;
            }
        });
    }
    openCloseConfirmCustomizationsModal(event) {
        this.openConfirmCustomizationsModal = false;
        if (event.status) {
            this._confirmCustomizations();
        }
    }
    _confirmCustomizations() {
        this.templatePriority.customizations = [];
        const customPriorities = this.templateEditedEventTypeSwitchers
            .map((ets) => ({
            event_type: ets.name,
            priority: ets.priority
        }));
        this.templatePriority.customizations = customPriorities;
        this.close.emit(this.templatePriority);
    }
    _buildEventTypeDisplayLabel(eventType) {
        return eventType.split('_').map(splittedItem => splittedItem[0].toUpperCase() + splittedItem.slice(1).toLowerCase()).join(' ');
    }
    static { this.ɵfac = function SidebarComponent_Factory(t) { return new (t || SidebarComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SidebarComponent, selectors: [["app-sidebar"]], inputs: { templatePriority: "templatePriority", eventTypes: "eventTypes", dropdownSelectedEntries: "dropdownSelectedEntries" }, outputs: { close: "close" }, features: [i0.ɵɵNgOnChangesFeature], decls: 6, vars: 11, consts: [["specified-width", "350px", "render-side", "right", "z-index", "100", "background-overlay-color", "rgba(51, 51, 51, 0.5)", "transition-duration", "0.5s", "renderSide", "right", "header-content", "Right sidebar", "closes-on-apply", "closesApply", "default-footer-content", "", "background-overlay", "", "scrollable", "", 3, "headerContent", "defaultHeaderContent", "hideCloseIcon", "cancelButtonDisabled", "handleOpenCloseInteraction", "handleCancelButtonInteraction", "handleApplyButtonInteraction", "isOpen"], [1, "hr", "m-3"], ["class", "mt-3", 4, "ngFor", "ngForOf"], [3, "message", "openModal", "outcome"], [1, "mt-3"], [1, "content-switcher-title"], [3, "contentList", "functionSetterData", "setIndexContentChosed", "indexContentChosed", "click"]], template: function SidebarComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "dsy-sidebar", 0)(1, "span");
            i0.ɵɵtext(2, "Allows to assign the priority for this template based on the Event Type.");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(3, "hr", 1);
            i0.ɵɵtemplate(4, SidebarComponent_div_4_Template, 4, 5, "div", 2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "app-confirm-modal", 3);
            i0.ɵɵlistener("outcome", function SidebarComponent_Template_app_confirm_modal_outcome_5_listener($event) { return ctx.openCloseConfirmCustomizationsModal($event); });
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵpropertyInterpolate("headerContent", ctx.templatePriority == null ? null : ctx.templatePriority.template_display_label);
            i0.ɵɵpropertyInterpolate("defaultHeaderContent", ctx.defaultHeaderContent);
            i0.ɵɵproperty("hideCloseIcon", false)("cancelButtonDisabled", false)("handleOpenCloseInteraction", ctx.handleOpenCloseInteraction)("handleCancelButtonInteraction", ctx.handleCancelButtonInteraction)("handleApplyButtonInteraction", ctx.handleApplyButtonInteraction)("isOpen", true);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngForOf", ctx.templateEditedEventTypeSwitchers);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("message", "Do you want to save changes?")("openModal", ctx.openConfirmCustomizationsModal);
        } }, dependencies: [i1.NgForOf, i2.ConfirmModalComponent] }); }
}
