export const environment = {
    production: true,
    contentLibraryUiRootUrl: 'https://contentlibrary.apps.enelx.com',
    contentLibraryApiBaseUrl: 'https://contentlibrary-api.apps.enelx.com',
    ngpServiceCatalogReferenceApiBaseUrl: 'https://reference.ngp.eis-svcs.enernoc.net',
    ngpServiceCatalogReferenceApiKey: 'Wh5oLPFpBS99zlX5x7tVw3OP9LJ7Kyhh49TzrEeR',
    infoPushApiBaseUrl: 'https://info-push-api.svcs.enelx.net',
    openAmUiRootUrl: 'https://openam.apps.enelx.com/openam/XUI/',
    openAmUiLoginUrl: 'https://openam.apps.enelx.com/openam/XUI/?realm=enelx&goto=https://contentlibrary.apps.enelx.com/&realm=enelx#login/',
    openAmUiLogoutUrl: 'https://openam.apps.enelx.com/openam/XUI/?realm=enelx#loggedOut/',
    openAmCookieDomain: '.enelx.com',
    userNavApi: 'https://global-nav-v2.eis-svcs.enernoc.net/api/v2',
    brands: {
        enelx: {
            baseUrl: '/',
        },
    },
    defaults: {
        brand: 'enelx',
        locale: 'en-us',
    }
};
