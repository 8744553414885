import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { factory } from '../shared/app-logging';
import * as i0 from "@angular/core";
const logger = factory.getLogger('ErrorDataService');
export class ErrorDataService {
    static overLoadError(err, message, concatMessage = true) {
        const errMessage = message ? message + (concatMessage ? ' ' + err.message : '') : err.message;
        const error = new Error(errMessage);
        logger.debug(message, error);
        if (err instanceof HttpErrorResponse) {
            return new HttpErrorResponse({
                error: error,
                headers: err.headers,
                status: err.status,
                statusText: err.statusText + ` ${message}`,
                url: err.url
            });
        }
        else {
            return error;
        }
    }
    constructor() {
        this.errorSource = new BehaviorSubject(null);
        this.currentError = this.errorSource.asObservable();
    }
    setError(err) {
        this.errorSource.next(err);
    }
    clearError() {
        this.setError(null);
    }
    static { this.ɵfac = function ErrorDataService_Factory(t) { return new (t || ErrorDataService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ErrorDataService, factory: ErrorDataService.ɵfac, providedIn: 'root' }); }
}
