/*
 * This piece of code was taken from:
 * https://stackoverflow.com/a/54734673/1977194
 * Thanks to @Jota.Toledo
 */
import { throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { factory } from '../app-logging';
import { AppConfig } from '../app-config';
import { HttpErrorResponse } from '@angular/common/http';
const defaultParams = {
    maxAttempts: AppConfig.retryCountHttpRequestError,
    scalingDuration: AppConfig.retryScalingDelayMs,
    shouldRetry: ({ status }) => status >= 400
};
export const genericRetryStrategy = (params = {}, loggerName, request) => (attempts) => attempts.pipe(mergeMap((error, i) => {
    const { maxAttempts, scalingDuration, shouldRetry } = { ...defaultParams, ...params };
    const retryAttempt = i + 1;
    // if maximum number of retries have been met
    // or is HTTPError and response is a status code we don't wish to retry, throw error
    if (retryAttempt > maxAttempts || ((error instanceof HttpErrorResponse) && !shouldRetry(error))) {
        factory.getLogger(loggerName || 'RxJS Utils').debug(`No more retries, throwing error!`, (error instanceof HttpErrorResponse) ? new Error(error.error.message || error.message) : error);
        return throwError(error);
    }
    factory.getLogger(loggerName || 'RxJS Utils').
        debug(`${request && request.url ? request.url + ' : ' : ''}Attempt ${retryAttempt}, retrying in ${retryAttempt * scalingDuration}ms`);
    // retry after 1s, 2s, etc...
    return timer(retryAttempt * scalingDuration);
}));
