import { BulkEditChangeTypeEnum } from './bulk-edit-change-type.enum';
import { factory } from '../shared/app-logging';
const logger = factory.getLogger('BulkEditChangeRecord');
export class BulkEditChangeRecord {
    static isObsoleteOldContent(ber) {
        const thereIsNotOldContent = typeof ber.oldText !== 'string';
        const currentContentIsNotDefault = ber.type !== BulkEditChangeTypeEnum.ADD;
        const currentDiffersFromOld = ber.currentText !== ber.oldText;
        return thereIsNotOldContent || (currentContentIsNotDefault && currentDiffersFromOld);
    }
    static getCurrentContent(component, locale, product) {
        let result = null;
        if (component && component.contents) {
            result = component.contents.find(cont => cont.locale === locale.localeName && cont.productId === product.id);
            if (!result) {
                result = component.contents.find(cont => cont.locale === locale.localeName);
                result = result ? result : component.contents[0];
            }
        }
        return result;
    }
    static getChangeType(currentContent, locale, product, newText) {
        const sameCombination = currentContent.locale === locale.localeName &&
            (product.id === currentContent.productId ||
                (!currentContent.productId && (!product.id || product.id === 'DEFAULT')));
        const currentText = currentContent.content ? currentContent.content.replace(/\r/g, '') : '';
        const currentLength = currentText.length;
        const newLength = newText.length;
        // const currentLength = currentText.trim().length;
        // const newLength = newText.trim().length;
        return (sameCombination && currentLength > 0 && newLength === 0) ? BulkEditChangeTypeEnum.REVERT :
            (sameCombination && currentLength > 0 && currentText !== newText) ? BulkEditChangeTypeEnum.MODIFY :
                (!sameCombination && newLength > 0) ? BulkEditChangeTypeEnum.ADD : BulkEditChangeTypeEnum.UNCHANGED;
    }
    static getNewContentByCurrentAndChange(component, current, change, locale, product, newText) {
        let result = null;
        if (component && component.contents) {
            switch (change) {
                case BulkEditChangeTypeEnum.UNCHANGED:
                    result = current;
                    break;
                case BulkEditChangeTypeEnum.ADD:
                    result = current.clone();
                    result.id = null;
                    result.isDefaultInd = false;
                    result.productId = product.id;
                    result.locale = locale.localeName;
                    result.content = newText;
                    break;
                case BulkEditChangeTypeEnum.MODIFY:
                    result = current.clone();
                    result.content = newText;
                    break;
                case BulkEditChangeTypeEnum.REVERT:
                    if (!product || !product.id || product.id.trim() !== 'DEFAULT') {
                        result = component.contents.find(cont => cont.locale !== locale.localeName);
                    }
                    else {
                        result = component.contents.find(cont => cont.locale === locale.localeName && cont.productId !== product.id);
                    }
                    result = result ? result : component.contents[0];
                    break;
                default:
                    result = current;
                    break;
            }
        }
        return result;
    }
    static getNewContent(component, locale, product, newText) {
        const current = BulkEditChangeRecord.getCurrentContent(component, locale, product);
        const type = BulkEditChangeRecord.getChangeType(current, locale, product, newText);
        return BulkEditChangeRecord.getNewContentByCurrentAndChange(component, current, type, locale, product, newText);
    }
    constructor(currentComponent, locale, product, deliveryPath, newText, oldText) {
        this.currentComponent = currentComponent;
        this.locale = locale;
        this.product = product;
        this.deliveryPath = deliveryPath;
        this.newText = newText;
        this.oldText = oldText;
        if (currentComponent && currentComponent.contents) {
            this.currentContent = BulkEditChangeRecord.getCurrentContent(currentComponent, locale, product);
            this.currentText = this.currentContent.content.replace(/\r/g, '');
            this.newText = newText ? newText.replace(/\r/g, '') : '';
            this.oldText = oldText ? oldText.replace(/\r/g, '') : oldText;
            this.type = BulkEditChangeRecord.getChangeType(this.currentContent, locale, product, this.newText);
            this.newContent = BulkEditChangeRecord.getNewContentByCurrentAndChange(currentComponent, this.currentContent, this.type, this.locale, this.product, this.newText);
            this.selected = true;
            this.persisted = false;
            this.error = undefined;
        }
        else {
            this.error = new Error('The component to be customized was not provided');
        }
    }
    isObsoleteOldContent() {
        return BulkEditChangeRecord.isObsoleteOldContent(this);
    }
}
