import * as i0 from "@angular/core";
export class TemplatesByProductLinePipe {
    transform(templates, productLine) {
        if (!templates || !productLine) {
            return templates;
        }
        return templates.filter(t => t.productLine === productLine.code);
    }
    static { this.ɵfac = function TemplatesByProductLinePipe_Factory(t) { return new (t || TemplatesByProductLinePipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "templatesByComponentType", type: TemplatesByProductLinePipe, pure: true }); }
}
