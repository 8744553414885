import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map } from 'rxjs/operators';
import { AuditBulkEditTemplateComponentContent } from '../model/audit-bulk-edit-template-component-content';
import { factory } from '../shared/app-logging';
import { throwError } from 'rxjs';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('AuditBulkEditTemplateComponentContentsService');
export class AuditBulkEditTemplateComponentContentsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    startBulkEditAuditSession(audit) {
        logger.trace('startBulkEditAuditSession called');
        return this.httpClient.post(environment.contentLibraryApiBaseUrl + AppConfig.urlAuditBulkEditTCC, audit).pipe(map(response => {
            logger.debug(JSON.stringify(response));
            if (response['code'] === 200) {
                logger.info('Bulk edit audit session started');
                return AuditBulkEditTemplateComponentContent.parse(response['data']);
            }
        }), catchError((err) => {
            const message = `In AuditBulkEditTemplateComponentContentsService.startBulkEditAuditSession(${audit}).`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    endBulkEditAuditSession(audit) {
        logger.trace('endBulkEditAuditSession called');
        return this.httpClient.put(environment.contentLibraryApiBaseUrl + AppConfig.urlAuditBulkEditTCC, audit).pipe(map(response => {
            logger.debug(JSON.stringify(response));
            if (response['code'] === 200) {
                logger.info('Bulk edit audit session ended');
                return AuditBulkEditTemplateComponentContent.parse(response['data']);
            }
        }), catchError((err) => {
            const message = `In AuditBulkEditTemplateComponentContentsService.endBulkEditAuditSession(${audit}).`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    static { this.ɵfac = function AuditBulkEditTemplateComponentContentsService_Factory(t) { return new (t || AuditBulkEditTemplateComponentContentsService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuditBulkEditTemplateComponentContentsService, factory: AuditBulkEditTemplateComponentContentsService.ɵfac, providedIn: 'root' }); }
}
