import { factory } from '../shared/app-logging';
const logger = factory.getLogger('PollyVoice');
export class PollyVoice {
    constructor(id, gender, languageCode, languageName, name) {
        this.id = id;
        this.gender = gender;
        this.languageCode = languageCode;
        this.languageName = languageName;
        this.name = name;
    }
}
