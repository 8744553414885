export class TtsApplication {
    constructor(id, name, displayLabel, outputype, s3Path, audioCommand, audioArgs, audioInput, audioOutput, pollyOutputFormat, pollySampleRate, pollyTextType) {
        this.id = id;
        this.name = name;
        this.displayLabel = displayLabel;
        this.outputype = outputype;
        this.s3Path = s3Path;
        this.audioCommand = audioCommand;
        this.audioArgs = audioArgs;
        this.audioInput = audioInput;
        this.audioOutput = audioOutput;
        this.pollyOutputFormat = pollyOutputFormat;
        this.pollySampleRate = pollySampleRate;
        this.pollyTextType = pollyTextType;
    }
}
export class TtsDefaultVoice {
    constructor(id, xcoreLocale, pollyLocale, pollyVoiceId) {
        this.id = id;
        this.xcoreLocale = xcoreLocale;
        this.pollyLocale = pollyLocale;
        this.pollyVoiceId = pollyVoiceId;
    }
}
export class TtsApplicationDefaults {
    constructor(app, defVoice) {
        this.app = app;
        this.defVoice = defVoice;
    }
    static parse(json) {
        const app = new TtsApplication(json.tts_application_id, json.tts_application_name, json.tts_application_display_label, json.tts_output_type, json.s3_path, json.audio_conversion_command, json.audio_conversion_args, json.audio_conversion_input, json.audio_conversion_output, json.tts_polly_output_format, json.tts_polly_sample_rate, json.tts_polly_text_type);
        const dv = new TtsDefaultVoice(json.tts_default_voice_id, json.xcore_locale, json.tts_polly_locale, json.tts_polly_voice_id);
        return new TtsApplicationDefaults(app, dv);
    }
}
