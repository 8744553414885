export class TemplateModel {
    constructor(name, label) {
        this.name = name;
        this.label = label;
    }
}
export class TemplatePriorityModel {
    constructor(template_name, template_display_label, event_types, priority, switcher, customizations) {
        this.template_name = template_name;
        this.template_display_label = template_display_label;
        this.event_types = event_types;
        this.priority = priority;
        this.switcher = switcher;
        this.customizations = customizations;
    }
}
export class EventTypeModel {
    constructor(code, label) {
        this.code = code;
        this.label = label;
    }
}
export class TemplatePriorityExportRequest {
    constructor(export_type, opp_list) {
        this.export_type = export_type;
        this.opp_list = opp_list;
    }
}
export class TemplatePriorityExportOppItem {
    constructor(product_id, product_display_label, program_id, program_display_label, operator_id, operator_display_label) {
        this.product_id = product_id;
        this.product_display_label = product_display_label;
        this.program_id = program_id;
        this.program_display_label = program_display_label;
        this.operator_id = operator_id;
        this.operator_display_label = operator_display_label;
    }
}
export class TemplatePriorityExportResponse {
    constructor(code, message, sheets) {
        this.code = code;
        this.message = message;
        this.sheets = sheets;
    }
}
