import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LocalesService } from './services/locales.service';
import { BulkEditExcelService } from './services/bulk-edit-excel.service';
import { OperatorProgramsService } from './services/operator-programs.service';
import { TemplateComponentTypesService } from './services/template-component-types.service';
import { TemplateComponentVariablesService } from './services/template-component-variables.service';
import { TemplateDeliveryPathsService } from './services/template-delivery-paths.service';
import { TemplateProductLinesService } from './services/template-product-lines.service';
import { TemplatesService } from './services/templates.service';
import { routingModule } from './app.routing';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FateHtmlParserService, FateModule, FateParserService } from 'fate-editor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ngfModule } from 'angular-file';
import { GlobalErrorHandler } from './shared/error-handlers/global-error-handler';
import { HttpErrorInterceptor } from './shared/error-handlers/http-error-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from './shared/security/token-interceptor';
import { RedirectGuard } from './guards/redirect.guard';
import { FateCustomParserService } from './services/fate-custom-parser.service';
import { FateCustomHtmlParserService } from './services/fate-custom-html-parser.service';
import { FileSaverModule } from 'ngx-filesaver';
import { CommonModule, DatePipe } from '@angular/common';
import { GlobalNavModule } from 'ngx-global-nav';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { analytics } from 'noc-analytics';
import { CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalFooterModule } from 'ngx-global-contact-footer/module';
import { TextInputHighlightModule } from './text-input-highlight';
import { PlyrModule } from './plyr/plyr.module';
import { NgxTextDiffModule } from './text-diff/ngx-text-diff.module';
import { ExportService } from './services/export.service';
import { MatTableModule } from '@angular/material/table';
import '@dsy/enelx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/collapse";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "ngx-toastr";
import * as i6 from "ngx-global-nav";
import * as i7 from "ngx-global-contact-footer/module";
import * as i8 from "@ngx-translate/core";
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
export class AppModule {
    static { this.ɵfac = function AppModule_Factory(t) { return new (t || AppModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
            BulkEditExcelService,
            LocalesService,
            OperatorProgramsService,
            TemplateComponentTypesService,
            TemplateComponentVariablesService,
            TemplateDeliveryPathsService,
            TemplateProductLinesService,
            TemplatesService,
            {
                provide: FateParserService,
                useClass: FateCustomParserService
            },
            {
                provide: FateHtmlParserService,
                useClass: FateCustomHtmlParserService
            },
            {
                provide: ErrorHandler,
                useClass: GlobalErrorHandler
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi: true
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HttpErrorInterceptor,
                multi: true
            },
            CookieService,
            RedirectGuard,
            DatePipe,
            TranslateService,
            { provide: NOC_ANALYTICS, useValue: analytics },
            NgxCookieService,
            ExportService
        ], imports: [BrowserModule,
            FormsModule,
            HttpClientModule,
            CommonModule,
            routingModule,
            FateModule,
            TooltipModule.forRoot(),
            CollapseModule.forRoot(),
            ModalModule.forRoot(),
            BrowserModule,
            ngfModule,
            ToastrModule.forRoot({
                timeOut: 8000,
                progressBar: true,
                closeButton: true,
                preventDuplicates: false,
                resetTimeoutOnDuplicate: true,
                enableHtml: true,
                tapToDismiss: false,
                extendedTimeOut: 20000,
                // disableTimeOut: true,
                // positionClass: 'toast-bottom-right',
                positionClass: 'toast-bottom-full-width',
                iconClasses: {
                    error: 'toast-error ',
                    info: 'toast-info',
                    success: 'toast-success',
                    warning: 'toast-warning'
                }
            }),
            BrowserAnimationsModule,
            NgxTextDiffModule,
            PlyrModule,
            FileSaverModule,
            TextInputHighlightModule,
            GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
            GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                }
            }),
            GlobalFooterModule,
            MatTableModule] }); }
}
